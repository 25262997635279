.bonus-confirm-modal {

    .MuiPaper-root {
        min-width: 300px;
        border-radius: .5rem;
        color: #171e23;
        background: rgb(255, 249, 139);
        background: linear-gradient(180deg, #ffe100 0%, #ffab00 100%);
        margin: 1rem;
        margin-top: 2.5rem;
    }

    .MuiDialogActions-root {
        display: flex;
        justify-content: center;
    }

    .MuiTypography-h6 {
        color: #1c262e;
        font-family: $font-lilita;
        text-transform: uppercase;
        text-align: center;
        font-size: 24px;
        line-height: 1;
    }
}

.confirm-modal-button {
    min-width: 100px;
    width: auto;
    height: 36px;
    color: white;
    line-height: 1.1;
    padding: 0.25rem 0.5rem;
    font-size: 18px;
    font-family: $font-lilita;
    background-color: #1c262e;
    border-radius: 32px;
    position: relative;
    margin: 0 1rem;
    @include boxShadowDobule(inset 0.125em 0.125em 0.125em 0 rgba(255, 255, 255, 0.5), inset -0.125em -0.125em 0.125em 0 rgba(0, 0, 0, 0.5));
    border: 0;
    @include transition(.3s filter ease-in);

    &:hover {
        @include filter(brightness(110%));
    }
}