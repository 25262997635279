.game-play {
    display: flex;
    flex-direction: row;
    background-color: #313464;
    height: 400px;
    overflow-y: hidden;
    overflow-x: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    scroll-snap-stop: always;
    min-height: 400px;
    max-height: 400px;

    &:active {
        cursor: grabbing;
    }

    &::-webkit-scrollbar {
        display: none;
    }


}
