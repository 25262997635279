$font-regular: "Roboto-Regular", Roboto-Regular;
$font-semibold: "Roboto-Medium", Roboto-Medium;
$font-bold: "Roboto-Bold", Roboto-Bold;
$font-lilita: "LilitaOne-Regular", Roboto-Medium;

$blue-100: #070a2b; // header background color
$blue-200: #030520; // bottom navigator background color
$blue-300: #0e1340; // body background color
$blue-400: #070a2a; // menu item background color
$blue-500: #141945; // menu button text color
$blue-600: #7c84cb; // paragraph text color
$blue-700: #252b63; // chat background color
$blue-800: #3BC4F2; // spam filter text color
$blue-900: #1C1E3B; // spam filter progress bar background color
$blue-1000: #3C4384; // spam filter progress bar background color
$blue-1100: #191F52; 
$blue-1200: #20244A; // time frame filter hover 

$red-100: #DB1F35;

$green-100: #A7E623;

$yellow-100: #ffbf19;

$small: 576px;
$medium: 768px;
$large: 992px;
$x-large: 1200px;
$xx-large: 1400px;
