.jackpot-holder {
    position: absolute;
    top: .5rem;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(23, 30, 35, 0.7);
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: .25rem 1rem;
    border-radius: 2rem;
    line-height: 1.2;
    height: 46px;
    min-width: 260px;
    border: none;
}

.jackpot-odometer {
    font-family: $font-lilita;
    font-size: 22px;
    color: white;
    display: flex;
    align-items: flex-end;
}

.jackpot-holder-name {
    font-family: $font-bold;
    font-size: 12px;
    color: #b2adc7;
    text-transform: uppercase;
}

.jackpot-currency {
    font-size: 18px;
    margin-left: 0.25rem;
    margin-bottom: 1px;
}