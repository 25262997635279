@font-face {
  font-family: TitilliumWeb-Regular;
  src: url(../fonts/TitilliumWeb-Regular.ttf);
}

@font-face {
  font-family: TitilliumWeb-SemiBold;
  src: url(../fonts/TitilliumWeb-SemiBold.ttf);
}

@font-face {
  font-family: TitilliumWeb-Bold;
  src: url(../fonts/TitilliumWeb-Bold.ttf);
}

@font-face {
  font-family: Roboto-Bold;
  src: url(../fonts/Roboto-Bold.ttf);
}

@font-face {
  font-family: Roboto-Medium;
  src: url(../fonts/Roboto-Medium.ttf);
}

@font-face {
  font-family: Roboto-Regular;
  src: url(../fonts/Roboto-Regular.ttf);
}

@font-face {
  font-family: LilitaOne-Regular;
  src: url(../fonts/LilitaOne-Regular.ttf);
}

body,
html,
#root {
  width: 100%;
}

body {
  background-color: #191939;
  padding: 1rem;
  font-family: $font-regular;

    /* Media query for landscape */
  @media (max-height: 430px) and (max-width: $large) {
    zoom: 0.65;
    padding: 0;
  }

  &.landscape {
    overflow: hidden;
  }
}

.odometer-numbers>span:last-child {
  transition-duration: 0.7s !important;
}

.odometer-dot-coma {
  width: .5rem;
}

.close-icon {
  position: relative;
  width: 24px;
  height: 24px;
  opacity: 0.8;
  @include transition(.3s opacity);

  &:hover {
    opacity: 1;
  }

  &::before,
  &::after {
    content: ' ';
    position: absolute;
    left: 10px;
    height: 24px;
    width: 3px;
    background-color: white;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

.stop-auto-play {

  &::before {
    content: "";
    background: linear-gradient(180deg,
        rgb(247, 51, 51) 0%,
        rgb(247, 51, 51) 100%);
  }

  &::after {
    content: "";
    @include boxShadow(inset 0 0 0 1px rgb(247, 51, 51));
    background: linear-gradient(179deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(247, 51, 51, 1) 100%);
  }
}

.MuiButtonBase-root {
  font-family: $font-bold !important;
  color: white !important;
}

.MuiButton-colorSuccess {
  color: #01ff67 !important;
}

.MuiButton-colorError {
  color: #f73333 !important;
}