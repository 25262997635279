.game {
    position: relative;
    width: 100%;
    // background-color: #313464;
    background-color: #090C1D;
    border-radius: .5rem;
    display: flex;
    flex-direction: column;

    &.landscape {
      overflow: hidden;
    }
}

.landscape-message {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(7, 10, 43, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font-bold;
  font-size: 40px;
  color: white;
  z-index: 9999;
  padding: 2rem;
  text-align: center;
  line-height: 1.2;
}

.demo-badge {
    width: 85px;
    height: 88px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
  }
  
  .demo-badge .sticker {
    font-family: $font-bold;
    font-size: 14px;
    color: white;
    text-align: center;
    text-shadow: rgba(255, 255, 255, 0.5) 0px 1px 0px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    position: relative;
    padding: 7px 0;
    left: -29px;
    top: 12px;
    width: 120px;
    background-color: $red-100;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#BFDC7A), to(#8EBF45));
    background-image: -webkit-linear-gradient(top, #EF5350, #F44336);
    background-image: -moz-linear-gradient(top, #EF5350, #F44336);
    background-image: -ms-linear-gradient(top, #EF5350, #F44336);
    background-image: -o-linear-gradient(top, #EF5350, #F44336);
    color: #FFFFFF;
    -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  }