.max-escaped-box-value{
    font-family: $font-lilita;
    font-size: 45px;
    color: white;
    text-align: center;
    text-shadow: 2px 2px 4px #000;
}
.max-escaped-box{
    margin-top: -23%;
    font-size: 45px;
    text-shadow: 
    2px 2px 4px #000;
   -webkit-text-stroke: unset;
}