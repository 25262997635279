.winning-streak-holder {
  position: absolute;
  top: 338px;
  right: 4px;
  width: auto;
  height: 55px;
  border-radius: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-text-stroke: 2px black;
  -webkit-text-fill-color: white;
  font-family: $font-lilita;
  user-select: none;
  z-index: 5;
  padding: 0 .5rem;

  @media(min-width: $large){
    top: 4px;
  }
}
.streak-fire {
  width: 25px;
  margin-left: 5px;
}
