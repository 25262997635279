.jackpot-modal {
    &.MuiDialog-root {
        bottom: inherit;
        top: 1rem;
    }

    .MuiPaper-root {
        background-color: transparent;
        overflow: visible;
        box-shadow: none;

        @media (max-height: 430px) and (max-width: $large) {
            max-width: 330px;
          }
    }

}

.jackpot-box {
    margin: 0 auto;
    margin-top: -50%;
    position: relative;
    height: auto;
    border: none;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    padding: 1rem;
    border-radius: 1rem;
    font-family: $font-lilita;
    font-size: 24px;
    text-align: center;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #000000;
    line-height: 1;
    z-index: 1;
    text-transform: uppercase;
    @include boxShadow(0px 10px 20px 1px rgba(64, 57, 102, 1));

    @media (min-width: $large) {
        font-size: 28px;
    }



    &::before {
        content: "";
        position: absolute;
        top: 3px;
        right: 3px;
        bottom: 3px;
        left: 3px;
        border-radius: inherit;
        background: linear-gradient(180deg,
                rgb(255, 198, 0) 0%,
                rgb(255, 198, 0) 100%);
        z-index: -1;
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: inherit;
        z-index: -2;
        @include boxShadow(inset 0 0 0 1px rgb(255, 198, 0));
        background: linear-gradient(180deg,
                rgba(255, 255, 255, 1) 0%,
                rgba(220, 121, 0, 1) 100%);
    }
}

.jackpot-box-value {
    font-size: 32px;
    margin-top: .5rem;

    @media (min-width: $large) {
        font-size: 36px;
    }
}

.jackpot-box-value-currency {
    font-size: 24px;
    margin-left: .25rem;
    text-transform: uppercase;

    @media (min-width: $large) {
        font-size: 30px;
    } 
}