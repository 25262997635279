.hotkey-modal {
  &.MuiDialog-root {
    bottom: inherit;
    top: 1rem;
  }

  .MuiPaper-root {
    background-color: #171e23;
    padding: 0.5rem;
  }

  .modal-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    margin-bottom: 0.25rem;

    h2 {
      font-family: $font-bold;
      font-size: 16px;
      color: white;
      padding: 0 0.5rem;
    }
  }

  .MuiDialogContent-root {
    background-color: #2c3c48;
    padding: 2rem 1rem;
    border-radius: 0.75rem;
    color: white;
    padding-bottom: 0.75rem;
  }
}

.hotkey-checkbox {
  display: flex;
  padding: 0.5rem 0;
  margin-bottom: 1.5rem;

  background-color: #171e23;
  border-radius: 0.75rem;

  .MuiSvgIcon-root {
    fill: #108381;
  }
}

.hotkey-checkbox-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  font-family: $font-regular;
  margin-bottom: 0;
  padding: 0 0.5rem;

  span {
    font-family: $font-bold;
  }
}

.hotkey-info {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;

  p {
    margin-bottom: 0;
  }
}

.hotkey-key {
  border: 2px solid #171e23;
  background-color: #405768;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  min-width: 45px;
  max-width: 45px;

  &.space {
    max-width: none;
  }
}