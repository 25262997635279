.raccoon {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  position: absolute;
  top: 180px;
  left: 70px;
  //transition: transform 1s ease;
  width: 136px;
  min-height: 120px;

  &.start {
    // .raccoon-legs{
    //     animation: walk 0.45s forwards alternate;
    // }
    animation: startWalk 0.45s forwards alternate;
  }

  .raccoon-image,
  .raccoon-shadow {
    width: 70%;
  }

  .raccoon-image {
    position: relative;
    z-index: 2;
  }

  .raccoon-shadow {
    position: absolute;
    bottom: -.5rem;
    z-index: 1;
  }

  .raccoon-legs {
    position: absolute;
    bottom: -5px;
    left: 58px;
    width: 35%;
  }

  .raccoon-legs.walking {
    animation: walk 0.45s infinite alternate;
  }
}

@keyframes startWalk {
  0% {
    transform: translateX(-25px);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes walk {
  0% {
    transform: rotate(-5deg);
  }

  25% {
    transform: rotate(5deg);
  }

  50% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(5deg);
  }
}

@keyframes firstCash {
  0% {
    transform: translate(0, -10px) rotate(0);
  }

  50% {
    transform: translate(30px, 12px) rotate(-60deg);
  }

  100% {
    transform: translate(-5px, 25px) rotate(-25deg);
  }
}

@keyframes secondCash {
  0% {
    transform: translate(0, 0) rotate(0);
  }

  50% {
    transform: translate(-30px, 25px) rotate(25deg);
  }

  100% {
    transform: translate(5px, 50px) rotate(20deg);
  }
}

@keyframes thirdCash {
  0% {
    transform: translate(0, 0) rotate(0);
  }

  50% {
    transform: translate(30px, 12px) rotate(-60deg);
  }

  100% {
    transform: translate(-5px, 25px) rotate(-25deg);
  }
}

@keyframes forthCash {
  0% {
    transform: translate(0, 0) rotate(0);
  }

  50% {
    transform: translate(-30px, 25px) rotate(25deg);
  }

  100% {
    transform: translate(5px, 50px) rotate(20deg);
  }
}

.cash-image {
  width: 24px;
  position: absolute;
  z-index: 2;
}

.cash-image-b {
  top: 0;
  left: 5px;
  animation: firstCash ease-in 1.2s forwards;
}

.cash-image-c {
  top: 15px;
  left: 25px;
  animation: secondCash ease-in 1.2s forwards;
}

.cash-image-d {
  top: 10px;
  left: 50px;
  animation: thirdCash ease-in 1.2s forwards;
}

.cash-image-f {
  top: -10px;
  right: 0;
  animation: forthCash ease-in 1.2s forwards;
  z-index: 0;
}

.mp-holder {

  &.raccoon-mp-holder {
    position: absolute;
    color: white;
    font-family: $font-semibold !important;
    font-size: 16px !important;
    -webkit-text-stroke-width: 0;
    -webkit-text-stroke-color: transparent;
    background: rgb(38, 51, 61);
    background: linear-gradient(180deg,
        rgba(38, 51, 61, 1) 0%,
        rgba(23, 30, 35, 1) 90%);
    padding: .25rem .75rem;
    bottom: -30px;

    &::before {
      content: "";
      position: absolute;
      top: -1rem;
      /* Adjust based on how far you want the arrow to be from the div */
      left: 50%;
      transform: translateX(-50%);
      border-width: .5rem;
      /* Size of the arrow */
      border-style: solid;
      border-color: transparent transparent rgb(38, 51, 61) transparent;
    }

    .mp-holder-currency {
      font-size: 14px;
      text-transform: uppercase;
    }
  }
}

.raccoon-sprite-sheet {
  width: 122px;
  height: 118px;
  animation: sprite 1.8s steps(19) infinite;
  will-change: background-position;
  backface-visibility: hidden;
}

@keyframes sprite {
  from {
    background-position: 0 0%;
  }

  to {
    background-position: 0 100%;
  }
}

.raccoon-walking-sheet {
  width: 122px;
  height: 118px;
  animation: walking-sprite .85s steps(9) infinite;
  will-change: background-position;
  backface-visibility: hidden;
}

@keyframes walking-sprite {
  from {
    background-position: 0 0%;
  }

  to {
    background-position: 0 100%;
  }
}
