.game-controls {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #171e23;

  .react-tabs {
    padding: 0;
    padding-bottom: 0;

    @media (min-width: $large) {
      padding: 20px;
      padding-bottom: 0;
    }
  }

  .react-tabs__tab-list {
    border-bottom: none;
    margin: 0;
    width: 100%;
    max-width: 22rem;
    height: 3rem;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-shrink: 0;
    position: relative;
    display: flex; // temporarily hiding tabs
  }

  .react-tabs__tab {
    font-family: $font-semibold;
    color: white;
    // max-width: 160px;
    min-width: 160px;
    height: 36px;
    cursor: pointer;
    user-select: none;
    padding: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .react-tabs__tab--selected {
    border: none;
    // border-top: 2px solid $yellow-100;
    color: $yellow-100;
    background-color: transparent;
    border-radius: 0;
    font-family: $font-bold;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      width: 100%;
      height: 2px;
      background: rgb(253, 167, 0);
      background: linear-gradient(60deg,
          rgba(253, 167, 0, 0.3) 0%,
          rgba(253, 167, 0, 1) 50%,
          rgba(253, 167, 0, 0.3) 100%);
    }
  }

  .react-tabs__tab:focus:after {
    content: none;
  }
}

.game-controls-footer {
  width: auto;
  display: flex;
  justify-content: flex-end;
  padding: 1.5rem;
  align-items: center;
  border-top: 4px solid #2c3c48;
  bottom: 0;
  left: 1rem;
  right: 1rem;
  background: #171e23;
  z-index: 5;

  @media (min-width: $large) {
    width: 100%;
    bottom: inherit;
    left: inherit;
    right: inherit;
    background-color: transparent;
  }
}

.game-controls-footer-right {
  display: flex;
  z-index: 1;

  @media (min-width: $large) {
    position: absolute;
  }
}

.game-controls-footer-logo {
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  display: none;

  @media (min-width: $large) {
    display: flex;
  }
}

.game-controls-manual {
  padding-bottom: 1rem;
}

.game-controls-manual-holder {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 1rem;

  @media (min-width: $small) {
    padding-top: .5rem;
  }
  padding-top: 0;
}

.game-controls-manual-wrapper {
  padding: 1rem 0.5rem;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 10px;
  background: rgb(38, 51, 61);
  background: linear-gradient(180deg,
      rgba(38, 51, 61, 1) 0%,
      rgba(23, 30, 35, 1) 90%);

  @media (min-width: $large) {
    padding: 1.25rem;
  }
}

.game-controls-manual-label {
  font-family: $font-semibold;
  font-size: 14px;
  color: white;
  margin-bottom: 0.5rem;
  padding-left: 1rem;

  &.center {
    text-align: center;
  }

  &.with-time {
    display: flex;
    justify-content: space-between;
  }
}

.game-controls-manual-difficulty {
  width: 100%;
  border-radius: 1.5rem;
  background-color: #171e23;
  padding: 0.25rem;
  display: flex;

  &.disabled {
    opacity: 0.5;
    @include filter(brightness(0.8))
  }
}

.gcmd-button {
  flex: 1;
  background-color: #2c3c48;
  font-family: $font-bold;
  font-size: 14px;
  color: white;
  height: 40px;
  border: none;
  margin: 0 2px;
  padding: 0.25rem 0.5rem;

  &:first-child {
    border-top-left-radius: 22px;
    border-bottom-left-radius: 22px;
    margin-left: 0;
  }

  &:last-child {
    border-top-right-radius: 22px;
    border-bottom-right-radius: 22px;
    margin-right: 0;
  }

  &.active {
    color: #171e23;
    background: rgb(255, 249, 139);
    background: linear-gradient(180deg, #ffe100 0%, #ffab00 100%);
  }

  &.bet-amount-button {
    flex: 0;
  }
}

.bet-amount-buttons-holder {
  display: flex;
}

.bet-amount-holder {
  border-radius: 1.5rem;
  background-color: #171e23;
  padding: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include boxShadow(inset 0px 0px 5px 2px rgba(0, 0, 0, 0.75));

  &.disabled {
    opacity: 0.5;
    @include filter(brightness(0.8))
  }
}

.bet-amount-input-holder input {
  background-color: #171e23;
  color: #ffa800;
  font-family: $font-semibold;
  font-size: 1rem;
  border: none;
  height: 100%;

  &:focus {
    outline: none;
  }
}

.currency-symbol {
  color: #ffa800;
  font-family: $font-bold;
  font-size: 14px;
  padding: 0 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.bet-amount-input-wrapper {
  display: flex;
  max-width: 50%;
}

.betting-button-holder {
  order: -1;

  @media (min-width: $large) {
    order: 0;
  }
}

.green-button {
  background: rgb(1, 255, 103);
  background: linear-gradient(180deg,
      rgba(1, 255, 103, 1) 0%,
      rgba(15, 112, 47, 1) 100%);
  border-radius: 1.5rem;
  width: 388px;
  height: 39px;
  border: none;

  color: white;
  font-size: 1.3rem;
  font-family: $font-bold;
}

.gradient-border {
  position: relative;
  /* padding: 13px; */
  border-radius: 31px;
  background: white;
  z-index: 1;
  width: 100%;
  height: 38px;
  /* display: flex; */
  /* justify-content: center; */
  border: none;
  color: white;
  font-size: 1.3rem;
  font-family: $font-bold;

  &.disabled {
    opacity: 0.5;
    @include filter(brightness(0.8))
  }
}

.gradient-border::before {
  content: "";
  position: absolute;
  top: 3px;
  right: 3px;
  bottom: 3px;
  left: 3px;
  border-radius: inherit;
  background: linear-gradient(180deg,
      rgb(1, 255, 103) 0%,
      rgb(15, 112, 47) 100%);
  z-index: -1;
}

.gradient-border::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
  @include boxShadow(inset 0 0 0 1px #01d64b);
  z-index: -2;
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(15, 112, 47, 1) 100%);
}

.gradient-border {
  &.bonus-rounds {
    color: #1c262e;

    &::before {
      background: rgb(255, 245, 38);
      background: linear-gradient(180deg, rgba(255, 245, 38, 1) 0%, rgba(223, 143, 1, 1) 100%);
    }
    &::after {
      @include boxShadow(inset 0 0 0 1px rgb(255, 245, 38));
      background: rgb(255, 245, 38);
      background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(223, 143, 1, 1) 100%);
    }
  }
}

.input-holder {
  height: 48px;
  padding: 4px;
  border-radius: 1.5rem;
  display: flex;
  align-items: center;

  background: rgb(36, 49, 59);
  background: linear-gradient(180deg,
      rgba(36, 49, 59, 1) 0%,
      rgba(27, 35, 41, 1) 100%);
  @include boxShadow(0px 3px 10px 0px rgba(0, 0, 0, 0.75));

  &.reverse-row {
    flex-direction: row-reverse;

    input {
      padding: 0 0.8rem;
    }
  }

  input {
    background-color: transparent;
    color: #ffa800;
    font-family: $font-semibold;
    font-size: 1rem;
    border: none;
    height: 100%;
    width: 100%;

    &:focus {
      outline: none;
    }

    &:disabled {
      opacity: .6;
    }
  }

  .bet-amount-buttons-holder {
    display: none;

    &.show {
      display: flex;
      flex-shrink: 0;
      flex-basis: auto;
      flex-grow: 0;

      button {
        flex-shrink: 0;
        flex-basis: auto;
        flex-grow: 0;
      }
    }
  }
}

.profit-icon {
  color: white;
  font-size: 16px;
  font-family: $font-bold;
  padding: 0 0 0 .8rem;
  display: none;

  &.show {
    display: block;
  }
}

.custom-tooltip {
  background-color: #01aaa0 !important;

  .MuiTooltip-arrow {
    color: #01aaa0 !important;
  }
}

.custom-tooltip-text {
  font-family: $font-bold;
  color: white;
  text-align: center;
}

.pointer-element {
  cursor: pointer;
}

.button-icon{
  border: none;
  background: transparent;
  display: flex;
  padding: 0;
}

.jp-chance-message {
  font-family: $font-semibold;
  font-size: 18px;
  color: white;
  padding: .5rem 0;
  text-align: center;
}

.music-slider-holder {
  position: relative;
}

.music-slider {
  height: 50px;
  position: absolute;
  bottom: 100%;
  left: 40%;
  margin-bottom: .75rem;

  @media (min-width: $large) {
    height: 70px;
  }
}

.MuiSlider-thumb {
  width: 11px !important;
  height: 11px !important;
  background-color: white;
}

.MuiSlider-root {
  color: white !important;
}

.remaining-bets {
  font-family: $font-semibold;
  font-size: 14px;
  color: white;
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;
}

.game-controols-mobile {
  display: flex;
  justify-content: space-between;

}

.autoplay-button-label {
  font-family: $font-semibold;
  font-size: 14px;
  color: white;
  margin-top: 0.5rem;
  text-align: center;
}

.mobile-order-1 {
  order: 1;

  @media(min-width: $large) {
    order: unset;
  }
}

.mobile-order-2 {
  order: 2;

  @media(min-width: $large) {
    order: unset;
  }
}