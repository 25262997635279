.bet-history-modal {
    &.MuiDialog-root {
        bottom: 0;
        top: 1rem;
    }

    .MuiDialog-container {
        align-items: flex-start;
    }

    .MuiPaper-root {
        @media (max-width: 40em) {
            margin: 1rem;
            width: 100%;
            max-width: 100%;
        }

        padding: 0;
        background-color: #222d35;
        margin: 2rem;
        max-width: 100%;
        overflow: auto;
    }

    .MuiDialogContent-root {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        padding: 0;
        border-radius: 0;

        /* Firefox */
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .close-icon {
        width: 18px;
        height: 18px;

        &::before,
        &::after {
          content: ' ';
          position: absolute;
          left: 8px;
          height: 18px;
          width: 3px;
          background-color: white;
        }
    }

    .close-icon-holder {
        // position: absolute;
        // top: -1rem;
        // right: -1rem;
    }

    .difficulty-info-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        margin: 1rem .5rem;
        text-align: left;
        font-family: $font-regular;
        padding-left: .5rem;

        @media (max-width: 40em) {
            margin: 1rem;
            padding-left: .25rem;
        }
    }

    .responsiveTable {
        tbody {
            tr {
                border: none;
                // border-bottom: 2px solid white;

                &:last-child {
                    border-bottom: none;
                }

                @media (max-width: 40em) {
                    &:nth-child(even) {
                        background-color: #222d35;
                    }
                    &:nth-child(odd) {
                        background-color: transparent !important;
                    }
                }

                &:nth-child(odd) {
                    background-color: #222d35;
                }
            }
        }

        td.pivoted {
            @media (max-width: 40em) {
                text-transform: capitalize;
                text-align: right !important;
                padding-right: 1rem;
            }
        }
    }

    .react-tabs__tab-list {
        border-bottom: none;
    }

    .react-tabs__tab {
        font-family: $font-regular;
    }

    .react-tabs__tab:focus:after {
        content: none;
    }

    .react-tabs__tab--selected:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: rgb(253, 167, 0);
        background: linear-gradient(60deg, rgba(253, 167, 0, 0.3) 0%, rgb(253, 167, 0) 50%, rgba(253, 167, 0, 0.3) 100%);
    }

    .react-tabs__tab--selected {
        border-radius: 0;
        border-color: transparent;
        background: transparent;
        color: white;
    }
}

.bet-history-table-holder {
    border: none;
    border-radius: 0;
    overflow-x: auto;

    th {
        background-color: #2c3c48;
        border-bottom: none;
        font-family: $font-bold;
        font-size: 14px;
        color: white;
        padding: 0.5rem 1rem;
        text-align: left;
        line-height: 1.3;
        text-transform: capitalize;

        &:last-child {
            text-align: right;
        }
    }

    td {
        // border-bottom: 2px solid white;
        padding: 0.5rem 1rem;
        text-align: left;
        font-family: $font-regular;
        font-size: 14px;
        // border-right: 2px solid white;
        line-height: 1.3;

        @media (max-width: 40em) {
            padding: .25rem;
        }

        &:last-child {
            text-align: right;
        }
    }

    tr {
        &:last-child {
            td {
                border-bottom: none;
            }
        }
    }

    .no-data {
        width: 100%;
        min-width: 320px;
        min-height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        line-height: 1.3;
        padding: .5rem;
        // min-width: 100%;
        padding: 1rem;
    }

    .hide-br {

        @media (max-width: 40em) {
            display: none;
        }

        display: block;
    }
}

.bonus-history-table-holder {
    .bonus-type {
        text-transform: capitalize;
    }
}

.table-user-holder {
    display: flex;
    align-items: center;

    @media (max-width: 40em) {
        justify-content: flex-end;
    }

    .table-user-holder-image {
        width: 24px;
        height: 24px;
        margin-right: .5rem;
    }

    .table-user-holder-username {
        position: relative;
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: .5rem;
        margin-bottom: -2px;
    }
}