.end-game-modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .7);
    z-index: 6;
    height: 400px;
}

.end-game-modal-background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    overflow: hidden;
}

.top-row,
.bottom-row {
    display: flex;
    justify-content: space-between;
}

.end-game-modal-image {
    width: 50%;
    height: auto;
    max-height: 200px;
    opacity: 0;
    transition: opacity 1s ease-in;

    @media (min-width: $large) {
        width: auto;
        height: 200px;
        /* Adjust size as needed */
    }
}

.end-game-modal-image.visible {
    opacity: 1;
}

.end-game-modal-image.hidden {
    opacity: 0;
}