.popup-msg {
  color: white;
  background: center;
  height: auto;
  position: absolute;
  top: -100%;
  /* Start just above the viewport */
  left: 0;
  width: 100%;
  z-index: 99;
  text-align: center;
  background-color: black;
  opacity: 0.8;
  font-family: $font-bold;
  animation: moveDown 0.5s ease-out forwards;
  padding: 0.5rem;
  /* Apply the animation */

  &.error {
    color: #f73333;
  }

  &.info {
    color: #25c85f;
  }
}

@keyframes moveDownPopup {
  from {
    top: -100%;
    /* Start just above the viewport */
  }

  to {
    top: 0;
    /* End at the top of the viewport */
  }
}

@keyframes moveUpPopup {
  from {
    top: 0;
  }

  to {
    top: -100%;
  }
}