.line {
  background-position: center;
  // background-size: cover;
  min-width: 155px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  user-select: none;
  padding-top: 100px;
}

.walking-line {
  // background-image: url(/img/rr_street.png);
  border-right: 6px solid #a19ac3;
  background-color: #8e88ac;
  position: relative;

  &.is-jackpot {
    background-color: #625b83;

    &::after,
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: 36px;
      background-image: url(/img/jackpot-lane-light-min.png);
      background-repeat: repeat-y;
      background-size: 36px 36px;
      background-position: center;
    }

    &::after {
      left: inherit;
      right: -24px;
    }

    &::before {
      left: -4px;
    }
  }


  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 5px;
    /* Adjust as needed */
    height: 100%;
    /* Adjust as needed */
    // background: repeating-linear-gradient(to bottom, white, white 35px, transparent 35px, transparent 70px);
    background: repeating-linear-gradient(to bottom, white, white 25px, transparent 25px, transparent 60px);
  }
}

.jackpot-lane-text {
  font-family: $font-lilita;
  font-size: 22px;
  color: #ffba00;
  text-align: center;
  line-height: 1;
  margin: 1.5rem 0;
  text-transform: uppercase;
}

.walking-line:nth-last-of-type(2) {
  border-right: none;
}

.start-line {
  // background-image: url(/img/rr_bank_start.png);
  min-width: 180px;
  // background-size: auto;
  background-size: cover;
  background-repeat: repeat-y;
  // background-position: top;
  background-position-y: top;
}

.end-line {
  background-image: url(/img/rr_beach_end.png);
  min-width: 180px;
  background-size: auto;
  background-size: cover;
  background-repeat: repeat-y;
  background-position: top;
}

.car {
  position: absolute;
  width: 80%;
  height: 80%;
  top: 0;
  transform: translateY(-100%);
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 3;
}

@keyframes moveDown {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(calc(100vh + 200px));
    /* Ensure car moves beyond viewport */
  }
}

.line-coin {
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;
  // opacity: 0.85;
  padding: 0;
  // background-color: rgba(0, 0, 0, 0.8);
  background-color: #8e88ac;
  border-radius: 100%;
  border: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1;

  &.active {
    background-color: transparent;
    // opacity: 1;

    .line-coin-image {
      opacity: 1;
    }

    .line-coin-odd,
    .line-coin-currency {
      opacity: 1;
    }
  }

  &.auto-play-tab {
    background-color: transparent;

    .line-coin-image {
      opacity: 1;
    }

    .line-coin-odd,
    .line-coin-currency {
      opacity: 1;
    }

    &:hover {
      .line-coin-image {
        opacity: .8;
      }

      .line-coin-odd,
      .line-coin-currency {
        opacity: .8;
      }
    }
  }

  &.selected-auto-play {
    background-color: #ffe100;

    .line-coin-image {
      opacity: .8;
    }

    .line-coin-odd,
    .line-coin-currency {
      opacity: .8;
    }

    &:hover {
      .line-coin-image {
        opacity: .75;
      }

      .line-coin-odd,
      .line-coin-currency {
        opacity: .75;
      }
    }

    &.auto-play-active {
      background-color: #ffe100;
      cursor: default;
  
      .line-coin-image {
        opacity: .75;
      }
  
      .line-coin-odd,
      .line-coin-currency {
        opacity: 0.6;
      }
  
      &:hover {
        .line-coin-image {
          opacity: .75;
        }
  
        .line-coin-odd,
        .line-coin-currency {
          opacity: 0.6;
        }
      }
    }
  }

  &.auto-play-active {
    background-color: #8e88ac;
    cursor: default;

    .line-coin-image {
      opacity: .75;
    }

    .line-coin-odd,
    .line-coin-currency {
      opacity: 0.6;
    }

    &:hover {
      .line-coin-image {
        opacity: .75;
      }

      .line-coin-odd,
      .line-coin-currency {
        opacity: 0.6;
      }
    }
  }

  .line-coin-image {
    width: 100%;
    height: 100%;
    opacity: .75;
    position: absolute;
    pointer-events: none;
    user-select: none;
  }

  .line-coin-image-jackpot {
    width: 145%;
    height: 145%;
  }

  .line-coin-odd,
  .line-coin-currency {
    position: relative;
    font-family: $font-lilita;
    font-size: 22px;
    background: -webkit-linear-gradient(#fff510, #fbc21f);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #192068;
    opacity: 0.6;
  }

  .line-coin-currency {
    font-size: 18px;
    text-transform: uppercase;
  }
}

.win-coin-holder {
  display: flex;
  justify-content: center;
  align-items: center;
}

.win-coin {
  width: 80%;
  position: relative;
  z-index: 1;
  opacity: 0;
}


.flip-grow-animation {
  animation: 0.4s ease-out 0.25s 1 normal forwards running flipGrow;
  /* Flip and grow animation for 1 second */
}

// @keyframes flipGrow {
//   0% {
//     transform: scale(0.8) rotateY(75deg);
//   }
//   50% {
//     transform: scale(1) rotateY(180deg);
//   }
//   100% {
//     transform: scale(1) rotateY(0);
//   }
// }

@keyframes flipGrow {
  0% {
    opacity: 0;
    transform: translateY(0%) rotateY(180deg) scale(0.5) translateZ(0px);
  }

  50% {
    opacity: 1;
    transform: translateY(-50%) rotateY(90deg) scale(1) translateZ(0px);
  }

  100% {
    opacity: 1;
    transform: translateY(0%) rotateY(0deg) scale(1) translateZ(0px);
  }
}

.roadblock-holder {
  position: absolute;
  top: -100%;
  /* Start off-screen */
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fall .3s ease-out forwards;
  /* Falling animation for 1 second */
}

@keyframes fall {
  0% {
    top: -100%;
    /* Start off-screen */
  }

  100% {
    top: 4rem;
    /* End position */
  }
}

.mp-holder {
  width: auto !important;
  padding: 0 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: .5rem !important;
  font-family: $font-lilita !important;
  font-size: 22px !important;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #000000;
  position: absolute !important;
  bottom: -60px;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  right: inherit;
  line-height: 1;

  &.move-up {
    bottom: 30%;
    animation: moveUpAndDisappear 3s forwards;
    /* Apply the animation */
  }
}

@keyframes moveUpAndDisappear {
  0% {
    bottom: 30%;
    opacity: 1;
  }

  50% {
    bottom: 75%;
    /* Move to the middle */
    opacity: 0.7;
  }

  100% {
    bottom: 80%;
    /* Stay in the middle */
    opacity: 0;
    /* Fade out */
  }
}

.gradient-border {

  &.mp-holder {
    height: 44px;
    min-width: 80%;
    @include boxShadow(0px 10px 20px 1px rgba(64, 57, 102, 1));
    z-index: 2;

    .mp-holder-currency {
      text-transform: uppercase;
      font-size: 16px;
    }
  }

  &.hit-line-holder {
    height: 44px;
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    min-width: 80%;
    padding: 0 .75rem;
    border-radius: .5rem !important;
    font-family: $font-lilita !important;
    font-size: 22px !important;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #000000;
    line-height: 1;
    @include boxShadow(0px 10px 20px 1px rgba(64, 57, 102, 1));

    &::before {
      content: "";
      background: linear-gradient(180deg,
          rgb(247, 51, 51) 0%,
          rgb(247, 51, 51) 100%);
    }

    &::after {
      content: "";
      @include boxShadow(inset 0 0 0 1px rgb(247, 51, 51));
      background: linear-gradient(179deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(247, 51, 51, 1) 100%);
    }

    .mp-holder-currency {
      font-size: 16px;
      text-transform: uppercase;
    }
  }
}