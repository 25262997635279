.difficulty-info-title {
    font-family: $font-bold;
    color: white;
    font-size: 1.2rem;
    margin-bottom: 0.3rem;
    text-align: center;
  }
  .difficulty-info-text {
    font-family: $font-regular;
    color: white;
    font-size: 1rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .table-holder {
    border: 2px solid white;
    border-radius: 0.5rem;
  }
  .difficuloty-table {
    border-collapse: collapse;
  
    width: 75vw;

    @media (min-width: $small) {
      width: 400px;
    }
  
    td {
      border-bottom: 2px solid white;
      padding: 8px;
      text-align: center;
  
      &:first-child {
        color: #405768;
        background-color: white;
        border-bottom: 2px solid #405768;
        font-family: $font-bold;
      }
    }
  
    tr {
      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }
  
  .sign-pole {
    width: .75rem;
    background-color: #a8b0b7;
    position: absolute;
    top: -100px;
    height: 100px;
  
    &.left {
      left: 16%;
    }
    &.right {
      right: 16%;
    }
  }
  
  .difficulity-modal {
    .MuiPaper-root {
      overflow: visible;
    }
  }
  